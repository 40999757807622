import { Injectable, inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from "@angular/router";
import { AuthService } from "app/core/auth/auth.service";
import { Observable, of, switchMap } from "rxjs";
import { UserService } from "app/core/user/user.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     */
    status: string;
    constructor(private _authService: AuthService, private _router: Router, private _userService: UserService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let redirectUrl = state.url === "/sign-out" ? "/" : state.url;
        const clientToken = route.queryParams["clientToken"];
        const docId = route.queryParams["docId"];

        if (clientToken && docId) {
            return this._authService.validateClientApp(clientToken, Number(docId)).pipe(
                switchMap(response => {
                    if (response.valid) {
                        // If valid, navigate to the prep-document component
                        const redirectURL = "/documents/prepare";
                        this._router.navigate([redirectURL], {
                            queryParams: {
                                pI: btoa(docId),
                                dN: btoa(response.fileName),
                                mF: btoa("false"),
                                iU: btoa("true"),
                                dO: btoa(localStorage.getItem("directory")),
                                sS: btoa(response.selfSign),
                                dS: btoa(response.documentStatus)
                            }
                        });
                        return of(true);
                    } else {
                        // If not valid, redirect to an error page
                        this._router.navigate(["/error"]);
                        return of(false);
                    }
                })
            );
        } else {
            return this._check(redirectUrl);
        }
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === "/sign-out" ? "/" : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check("/");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {
        console.log(redirectURL, "authguard url ");

        return this._authService.check(redirectURL).pipe(
            switchMap(authenticated => {
                // If the user is not authenticated...
                console.log("receiving ", authenticated, " in auth guard ts");
                if (!authenticated) {
                    localStorage.setItem(
                        "accessToken",
                        "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjQ4NDY0ODk2LCJleHAiOjE2NDg0ODY0OTZ9.201mCFEoEcnzPqWo5nlPF_T9ZgSntjiSX5LYXtd04Og"
                    );
                    // Redirect to the sign-in page
                    this._router.navigate(["sign-in"], { queryParams: { redirectURL } });

                    // Prevent the access
                    return of(false);
                }

                return of(true);
            })
        );
    }
}
