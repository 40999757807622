import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { Observable, forkJoin } from 'rxjs';
import { UserService } from './core/user/user.service';
import { SettingService } from './core/setting/setting.service';
import { IdleService } from './idle.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private _messagesService: MessagesService,
        private _navigationService: NavigationService,
        private _notificationsService: NotificationsService,
        private _quickChatService: QuickChatService,
        private _shortcutsService: ShortcutsService,
        private _userService: UserService,
        private _settingService: SettingService,
        private idleService: IdleService,
        private _translocoService: TranslocoService
    ) {
        this.initialIdleSettings();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    private initialIdleSettings() {
        const idleTimeoutInSeconds: number = 15 * 60;
        this.idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
            if (isTimeOut) {
                this.idleService.openConformation("Session timeout. You are about to sign out.");
            }
        });
    }

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const savedLang = localStorage.getItem('selectedLanguage') || 'en';
        this._translocoService.setActiveLang(savedLang);

        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._translocoService.load('en'),
            this._translocoService.load('tr'),
            this._translocoService.load('es'),
            this._settingService.get(),
            this._userService.get(),
            this._navigationService.get(),
        ]);
    }
}
