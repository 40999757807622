import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { appConfig } from "app/core/config/app.config.dev";
import { UserSetting } from "./setting.type";

@Injectable({
  providedIn: "root",
})
export class SettingService {

  private _cachedUserSetting: UserSetting | null = null;

  private _userSettings: ReplaySubject<UserSetting> = new ReplaySubject<UserSetting>(1);

  constructor(private _httpClient: HttpClient) { }

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set userSetting(value: UserSetting) {
    // Store the value
    this._userSettings.next(value);
  }

  get userSetting$(): Observable<UserSetting> {
    return this._userSettings.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<UserSetting> {
    // if (this._cachedUserSetting) {
    //   return of(this._cachedUserSetting);
    // }

    return this._httpClient.get<UserSetting>(appConfig.basePath + "user/v0/settings/signatures").pipe(
      tap((signatureImage) => {
        // this._cachedUserSetting = signatureImage;
        this._userSettings.next(signatureImage);
      })
    );
  }

  clearUserSettingCache(): void {
    this._cachedUserSetting = null;
}

  updateUserName(name: string): Observable<any> {
    return this._httpClient.put(appConfig.basePath + "user/v0/settings/profile", { name }).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  updatePassword(settingObject: { secretEnabled: Boolean; secretQuestion: string; secret: string; mfaEnabled: Boolean; tOtp: string }): Observable<any> {
    return this._httpClient.put(appConfig.basePath + "user/v0/settings/security", settingObject).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  generateQr(): Observable<any> {
    // return this._httpClient.get(appConfig.basePath + "user/v0/settings/generateQR", { responseType: "arraybuffer" }).pipe(
    //   map((response: ArrayBuffer) => {
    //     const base64Image = this.arrayBufferToBase64(response);
    //     return "data:image/png;base64," + base64Image;
    //   })
    // );

    return this._httpClient.get(appConfig.basePath + "user/v0/settings/generateQR");
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = "";
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
}
